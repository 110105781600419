import React from 'react'
import {
  Checkbox,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
} from '@material-ui/core'
import { Service, UserService } from '../../../api'
import { StickyTable } from '../../../components'
import { ServiceEnabledLabel } from './ServiceEnabledLabel'
import { SERVICE_TYPE } from '../../../Consts'

/**
 * スタイル(CSS)定義
 */
const useStyles = makeStyles(() => ({
  partnerCompanyUserTable: {
    tableLayout: 'fixed',
  },
  checkboxTableCell: {
    padding: 0,
    width: '45px',
    textAlign: 'center',
  },
  userNameHeader: {
    width: '17%',
    textAlign: 'center',
  },
  userNameKanaHeader: {
    width: '17%',
    textAlign: 'center',
  },
  departmentNameHeader: {
    width: '13%',
    textAlign: 'center',
  },
  positionNameHeader: {
    width: '13%',
    textAlign: 'center',
  },
  primeCompanyServiceHeader: {
    wordBreak: 'keep-all',
    wordWrap: 'break-word',
    width: '8%',
    textAlign: 'center',
    fontSize: '0.65vw',
    lineHeight: '15px',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableCellWrap: {
    overflowWrap: 'anywhere',
  },
}))

/**
 * サービス名称を改行する
 * @param props
 * @returns
 */
const ServiceNameLineBreak: React.FC<{
  serviceId: number
  serviceName: string
}> = (props) => {
  const { serviceId, serviceName } = props

  if (serviceId === SERVICE_TYPE.SAGYOSHO_NET) {
    return <div>ファイル<br />ポータル</div>
  } else if (serviceId === SERVICE_TYPE.TAISEI_SECURITY) {
    return <div>情報<br />セキュリティ</div>
  } else if (serviceId === SERVICE_TYPE.MOBILE_WORKS) {
    return <div>DandALL<br />チャット</div>
  }

  return <div>{serviceName}</div>
}

/**
 * 一覧データ項目
 */
export type UserTableRowData = {
  primeCompanyServices: boolean[]
  mcdpId: string
  userName: string
  userNameKana: string
  departmentName: string
  position: string
  enabled: boolean
  updateDatetime?: string | null
  userServices?: UserService[]
  key: string
  selected: boolean // 表の左部分のチェックボックスの有無を判定するフラグ
}

/**
 * 協力会社ユーザ一覧テーブル Props
 */
type PartnerCompanyUserTableProps = {
  /** ユーザ一覧 */
  users: UserTableRowData[]
  /** サービス */
  services?: Service[]
  /** ヘッダー行のチェックボックスをクリックした時のアクション */
  handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  /** 行をクリックした時のアクション */
  handleRowClick: (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    key: string,
  ) => void
  /** checkbox の表示フラグ */
  showCheckBox: boolean
}
/**
 * 協力会社ユーザ一覧テーブル ユーザー追加済・未追加どちらの画面でも利用する
 * @param param
 * @returns
 */

export const PartnerCompanyUserTable: React.FC<PartnerCompanyUserTableProps> = (
  props,
) => {
  const classes = useStyles()
  const {
    users,
    services,
    handleRowClick,
    handleSelectAllClick,
    showCheckBox,
  } = props

  return (
    <StickyTable className={classes.partnerCompanyUserTable}>
      <TableHead>
        <TableRow>
          {showCheckBox && (
            <TableCell
              key="projectId"
              className={classes.checkboxTableCell}
              data-test="userSelectAll-table-cell"
            >
              <Checkbox
                onChange={handleSelectAllClick}
                disabled={users.length < 1}
                checked={users.length > 0 && users.every((u) => u.selected)}
                name="row-selected-all"
              />
            </TableCell>
          )}
          <TableCell key="userName" className={classes.userNameHeader}>
            ユーザー名
          </TableCell>
          <TableCell key="userNameKana" className={classes.userNameKanaHeader}>
            ユーザーカナ名
          </TableCell>
          <TableCell
            key="departmentName"
            className={classes.departmentNameHeader}
          >
            部署
          </TableCell>
          <TableCell key="positionName" className={classes.positionNameHeader}>
            役職
          </TableCell>
          {services?.map((x, i) => (
            <TableCell
              key={'primeCompanyServices_' + i}
              className={classes.primeCompanyServiceHeader}
            >
              <ServiceNameLineBreak
                serviceId={x.serviceId}
                serviceName={x.serviceName}
              />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((row) => {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={row.key}
              className={showCheckBox ? classes.tableRow : ''}
              onClick={(e) => {
                if (showCheckBox) {
                  handleRowClick(e, row.key)
                }
              }}
            >
              {showCheckBox && (
                <TableCell
                  align="center"
                  className={classes.checkboxTableCell}
                  data-test="userSelect-table-cell"
                >
                  <Checkbox checked={row.selected} name="row-selected" />
                </TableCell>
              )}
              <TableCell data-test="userName-table-cell">
                {row.userName}
              </TableCell>
              <TableCell data-test="userNameKana-table-cell">
                {row.userNameKana}
              </TableCell>
              <TableCell
                className={classes.tableCellWrap}
                data-test="departmentName-table-cell"
              >
                {row.departmentName}
              </TableCell>
              <TableCell
                className={classes.tableCellWrap}
                data-test="positionName-table-cell"
              >
                {row.position}
              </TableCell>
              {row.primeCompanyServices.map((enabled, index) => {
                return (
                  <TableCell
                    align="center"
                    key={`primeCompanyServices-enabled-${row.key}-${index}`}
                    data-test={'primeCompanyServices-table-cell_' + index}
                  >
                    <ServiceEnabledLabel enabled={enabled} />
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </StickyTable>
  )
}
