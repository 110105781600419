import React from 'react'
import { useParams, Link as RouterLink } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'
import {
  Grid,
  TablePagination,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import {
  ContentTitle,
  InlineContainer,
  SearchButton,
  PageHeaderButton,
  HistoryBackButton,
  StyledTextField,
  MultiSelectCheckbox,
  SelectCheckboxProps,
  ProjectServiceIconsWithCaption,
  ErrorMessageContainer,
  ContentSubTitle,
  ConfirmDialogButton,
} from '../../../components'
import { useAuth, useTitle, useHistoryQuery } from '../../../hooks'
import {
  usePartnerCompanyUsersApi,
  usePartnerCompanyUsersPostApi,
  usePartnerCompanyProjectApi,
  usePrimeCompanyServicesApi,
  ProjectStatus,
  MANAGER_TYPE,
  PrimeCompanyServicesResponse,
  UserService,
} from '../../../api'
import { ListPageTemplate as Template } from '../../template'
import { useQueryState } from 'react-router-use-location-state'
import { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { parseNumArray } from '../../../api/util'
import { useThrottledCallback } from 'use-debounce'
import { FooterButtonContainer } from '../../footer/FooterButtonContainer'
import {
  PartnerCompanyUserTable,
  UserTableRowData,
} from './PartnerCompanyUserTable'

const NO_DATA_MESSAGE = 'データが見つかりませんでした。'
const PAGE_CONTENT_HEIGHT = '50vh'

/**
 * 検索条件項目
 */
type PartnerCompanyUsersSearchCondition = Partial<{
  userName: string
  userNameKana: string
  departmentName: string
  primeCompanyServices: number[]
}>

/**
 * スタイル(CSS)定義
 */
const useStyles = makeStyles(() => ({
  multiSelectCheckbox: {
    width: '547px', // レスポンシブ的にしたいが固定幅でないと検索条件が崩れるのでこのようにする。
  },
  userName: {
    width: '270px',
  },
  departmentName: {
    width: '548px',
  },
  description: {
    fontSize: 13,
    marginLeft: 24,
    whiteSpace: 'pre-line',
  },
}))

/**
 * パスパラメーター項目
 */
type PartnerCompanyUsersParams = {
  partnerCompanyId: string
  projectId: string
  companyPositionId: string
}

type PartnerCompanyUsersProps = {
  prime: boolean
}

/**
 * 協力会社ユーザー一覧画面
 */
export const PartnerCompanyUsersPage: React.FC<PartnerCompanyUsersProps> = (
  props,
) => {
  const PAGE_TITLE = '協力会社ユーザー一覧'
  useTitle(PAGE_TITLE)

  const { user } = useAuth()

  // 検索条件をクエリストリングから取得する
  const [userNameQuery, setUserNameQuery] = useQueryState('userName', '')
  const [userNameKanaQuery, setUserNameKanaQuery] = useQueryState(
    'userNameKana',
    '',
  )
  const [departmentNameQuery, setDepartmentNameQuery] = useQueryState(
    'departmentName',
    '',
  )
  const [serviceIdsQuery, setServiceIdsQuery] = useQueryState('serviceIds', '')
  const [page, setPage] = useQueryState('page', 0)
  const rowsPerPage = 100

  const classes = useStyles()
  const params = useParams<PartnerCompanyUsersParams>()

  const {
    data: partnerCompanyProjectData,
    loading: partnerCompanyProjectLoading,
  } = usePartnerCompanyProjectApi({
    prime: props.prime,
    partnerCompanyId: params.partnerCompanyId,
    projectId: params.projectId,
    companyPositionId: params.companyPositionId,
  })

  const primeCompanyServices = usePrimeCompanyServicesApi({
    prime: props.prime,
    partnerCompanyId: params.partnerCompanyId,
    projectId: params.projectId,
  })

  // サービス利用状況の選択状態
  const [selectedServiceIds, setSelectedServiceIds] = React.useState<string[]>(
    [],
  )
  useEffect(() => {
    setSelectedServiceIds(serviceIdsQuery.split(','))
  }, [serviceIdsQuery])

  const checkboxes =
    primeCompanyServices.data?.map<SelectCheckboxProps>((x) => {
      return { value: x.serviceId.toString(), label: x.serviceName }
    }) ?? []

  const { doFetch, ...partnerCompanyUsers } = usePartnerCompanyUsersApi(
    {
      prime: props.prime,
      partnerCompanyId: params.partnerCompanyId,
      projectId: params.projectId,
      companyPositionId: params.companyPositionId,
      queryParams: {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        userName: userNameQuery,
        userNameKana: userNameKanaQuery,
        departmentName: departmentNameQuery,
        serviceIds: parseNumArray(serviceIdsQuery),
        invited: true,
      },
    },
    {
      handleValidateFields: [
        'userName',
        'userNameKana',
        'departmentName',
        'serviceIds',
      ],
      message: {
        504: '予期せぬエラーが発生しました。再検索してください。',
      },
    },
  )

  // 一覧表示用データ
  const [users, setUsers] = React.useState<UserTableRowData[]>([])
  useEffect(() => {
    const getUserServicesEnabled = (
      primeCompanyServices?: PrimeCompanyServicesResponse,
      userServices?: UserService[],
    ) => {
      return (
        primeCompanyServices?.map(
          (y) =>
            userServices?.find((x) => x.serviceId === y.serviceId)?.enabled ??
            false,
        ) ?? []
      )
    }

    const users =
      partnerCompanyUsers.data?.partnerCompanyInvitedUsers?.map((x) => ({
        key: x.mcdpId,
        selected: false,
        primeCompanyServices: getUserServicesEnabled(
          primeCompanyServices.data,
          x.userServices,
        ),
        ...x,
      })) ?? []
    setUsers(users)
  }, [partnerCompanyUsers.data, primeCompanyServices.data])

  const { doPost, ...partnerCompanyUsersPost } = usePartnerCompanyUsersPostApi({
    notFoundRedirect: false,
  })

  // ユーザー解除処理後のレスポンスデータを監視
  const { enqueueSnackbar } = useSnackbar()
  useEffect(() => {
    if (!partnerCompanyUsersPost.data) {
      // エラー時(データが取得できていない場合)は処理終了
      return
    }

    enqueueSnackbar('ユーザーをプロジェクトから解除しました。', {
      variant: 'success',
      anchorOrigin: { horizontal: 'left', vertical: 'top' },
    })

    // 表示ページをリフレッシュ
    doFetch({
      prime: props.prime,
      partnerCompanyId: params.partnerCompanyId,
      projectId: params.projectId,
      companyPositionId: params.companyPositionId,
      queryParams: {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        userName: userNameQuery,
        userNameKana: userNameKanaQuery,
        departmentName: departmentNameQuery,
        serviceIds: parseNumArray(serviceIdsQuery),
        invited: true,
      },
    })
  }, [partnerCompanyUsersPost.data])

  const pageContentLoading =
    partnerCompanyUsers.loading ||
    primeCompanyServices.loading ||
    partnerCompanyUsersPost.loading

  /**
   * ページヘッダーのエラーメッセージをクリアする。
   */
  const clearPageHeaderErrorMessage = () => {
    partnerCompanyUsers.clearError()
    partnerCompanyUsersPost.clearError()
  }

  /**
   * 検索ボタンクリック(submit)ハンドラ
   * @param data 検索条件
   */
  const onSubmit: SubmitHandler<PartnerCompanyUsersSearchCondition> =
    useThrottledCallback(
      (data) => {
        clearPageHeaderErrorMessage()
        doFetch({
          prime: props.prime,
          partnerCompanyId: params.partnerCompanyId,
          projectId: params.projectId,
          companyPositionId: params.companyPositionId,
          queryParams: {
            offset: 0,
            limit: rowsPerPage,
            userName: data.userName,
            userNameKana: data.userNameKana,
            departmentName: data.departmentName,
            serviceIds: data.primeCompanyServices,
            invited: true,
          },
        })
        setPage(0)
        setUserNameQuery(data.userName || '')
        setUserNameKanaQuery(data.userNameKana || '')
        setDepartmentNameQuery(data.departmentName || '')
        setServiceIdsQuery(data.primeCompanyServices?.join(',') || '')
      },
      1000,
      { trailing: false },
    )

  const { handleSubmit, register, errors } =
    useForm<PartnerCompanyUsersSearchCondition>({
      criteriaMode: 'all',
      reValidateMode: 'onSubmit',
    })

  /**
   * ページ変更ハンドラ
   * @param event イベント
   * @param newPage ページ番号
   */
  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    clearPageHeaderErrorMessage()
    doFetch({
      prime: props.prime,
      partnerCompanyId: params.partnerCompanyId,
      projectId: params.projectId,
      companyPositionId: params.companyPositionId,
      queryParams: {
        offset: newPage * rowsPerPage,
        limit: rowsPerPage,
        userName: userNameQuery,
        userNameKana: userNameKanaQuery,
        departmentName: departmentNameQuery,
        serviceIds: parseNumArray(serviceIdsQuery),
        invited: true,
      },
    })
    setPage(newPage)
  }

  /**
   * 行選択ハンドラ
   * @param event イベント
   * @param key キー値
   */
  const handleRowClick = (
    _event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    key: string,
  ) => {
    const newUsers = users.map((u) => {
      if (u.mcdpId === key) {
        return { ...u, selected: !u.selected }
      }

      return u
    })
    setUsers(newUsers)
  }

  /**
   * 全行選択(チェックボックス選択)ハンドラ
   * @param event イベント
   */
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newUsers = users.map((u) => ({
      ...u,
      selected: event.target.checked,
    }))
    setUsers(newUsers)
  }

  /**
   * ユーザー解除
   */
  const unInvite = useThrottledCallback(
    () => {
      clearPageHeaderErrorMessage()
      doPost({
        prime: props.prime,
        partnerCompanyId: params.partnerCompanyId,
        projectId: params.projectId,
        companyPositionId: params.companyPositionId,
        partnerCompanyUsers: users
          .filter((u) => u.selected)
          .map((u) => {
            return {
              mcdpId: u.mcdpId,
              enabled: false,
              updateDatetime: u.updateDatetime,
            }
          }),
      })
    },
    1000,
    { trailing: false },
  )

  const historyQuery = useHistoryQuery()
  //  戻り先URL（プロジェクト一覧画面)
  const backUrl = historyQuery.findByPath(
    `/partnerCompanies/${params.partnerCompanyId}/projects`,
  )

  // ユーザー追加画面URL
  const invitationPageUrl = props.prime
    ? `/prime/projects/${params.projectId}/partnerCompanies/${params?.partnerCompanyId}/companyPositions/${params.companyPositionId}/users/invitation`
    : `/partnerCompanies/${params?.partnerCompanyId}/projects/${params.projectId}/companyPositions/${params.companyPositionId}/users/invitation`

  /** ログインユーザーの管理者区分がヘルプデスク */
  const isHelpDesk = user?.managerType === MANAGER_TYPE.HELP_DESK

  /** プロジェクトが凍結中 */
  const isSuspend =
    !props.prime &&
    partnerCompanyProjectData?.projectStatus === ProjectStatus.SUSPEND

  // エラーメッセージ
  const [errorMessages, setErrorMessages] = React.useState<string[]>([])
  useEffect(() => {
    const messages = []

    // 検索結果なし
    if (partnerCompanyUsers.data && users.length === 0) {
      messages.push(NO_DATA_MESSAGE)
    }

    // APIエラー
    const partnerCompanyUsersError =
      partnerCompanyUsers.error?.errors
        .filter((error) => !error.field)
        .map((error) => error.message)
        .join('') || partnerCompanyUsers.error?.message
    if (partnerCompanyUsersError) {
      messages.push(partnerCompanyUsersError)
    }
    if (partnerCompanyUsersPost.error?.message) {
      messages.push(partnerCompanyUsersPost.error.message)
    }

    setErrorMessages(messages)
  }, [users, partnerCompanyUsers.error, partnerCompanyUsersPost.error])

  return (
    <Template.Container>
      <Template.Body>
        <Template.Header loading={partnerCompanyProjectLoading}>
          <div>
            <ContentTitle title={PAGE_TITLE} />
            <ContentSubTitle
              text={`プロジェクト名：${partnerCompanyProjectData?.projectName}`}
            />
            <ContentSubTitle
              text={`元請会社名：${partnerCompanyProjectData?.primeCompanyName}`}
            />
            <ContentSubTitle
              text={`協力会社名：${partnerCompanyProjectData?.companyName}`}
            />
            <ContentSubTitle
              text={`立場：${partnerCompanyProjectData?.companyPositionName}`}
            />
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <ProjectServiceIconsWithCaption
                services={partnerCompanyProjectData?.relatedServices}
                caption="利用サービス："
              />
              {!isSuspend && !isHelpDesk && (
                <span
                  style={{ paddingLeft: '0.5em' }}
                  data-test="invitation-page-link"
                >
                  <PageHeaderButton
                    component={RouterLink}
                    to={invitationPageUrl}
                  >
                    ユーザー追加
                  </PageHeaderButton>
                </span>
              )}
            </div>
          </div>
          <InlineContainer>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item xs>
                  <div>
                    <StyledTextField
                      name="userName"
                      label="ユーザー名"
                      inputRef={register}
                      formErrors={errors}
                      fetchErrors={partnerCompanyUsers.error}
                      defaultValue={userNameQuery}
                      className={classes.userName}
                      maxLength={40}
                    />
                    <StyledTextField
                      name="userNameKana"
                      label="ユーザーカナ名"
                      inputRef={register}
                      formErrors={errors}
                      fetchErrors={partnerCompanyUsers.error}
                      defaultValue={userNameKanaQuery}
                      className={classes.userName}
                      maxLength={40}
                    />
                  </div>
                  <StyledTextField
                    name="departmentName"
                    label="部署名"
                    inputRef={register}
                    formErrors={errors}
                    fetchErrors={partnerCompanyUsers.error}
                    defaultValue={departmentNameQuery}
                    className={classes.departmentName}
                    maxLength={150}
                  />
                  <MultiSelectCheckbox
                    label="サービス利用状況"
                    name="primeCompanyServices"
                    inputRef={register}
                    fetchErrors={partnerCompanyUsers.error}
                    fetchName="serviceIds"
                    checkboxes={checkboxes}
                    defaultValue={selectedServiceIds}
                    className={classes.multiSelectCheckbox}
                  />
                </Grid>
                <Grid item>
                  <SearchButton />
                </Grid>
              </Grid>
            </form>
          </InlineContainer>
        </Template.Header>
        {!partnerCompanyProjectLoading && (
          <Typography className={classes.description}>
            {`プロジェクトに追加設定されているユーザーの一覧になります。
          「ユーザー追加」より、元請企業のサービス利用設定がなされたユーザーを追加することができます。
          元請企業のサービス利用設定は、「サービス利用設定」から実施してください。`}
          </Typography>
        )}

        <Template.ErrorContent>
          {isSuspend && <SuspendMessageBar />}
          <div style={{ marginTop: '23px' }}>
            <ErrorMessageContainer messages={errorMessages} />
          </div>
        </Template.ErrorContent>

        <Template.Content
          loading={pageContentLoading}
          hidden={errorMessages.length > 0}
          height={PAGE_CONTENT_HEIGHT}
        >
          <PartnerCompanyUserTable
            users={users}
            services={primeCompanyServices.data}
            handleSelectAllClick={handleSelectAllClick}
            handleRowClick={handleRowClick}
            showCheckBox={!isSuspend && !isHelpDesk}
          />
        </Template.Content>
      </Template.Body>

      <Template.Footer loading={pageContentLoading}>
        {!errorMessages.length && (
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={partnerCompanyUsers.data?.count || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        )}

        <FooterButtonContainer>
          {!props.prime && (
            <HistoryBackButton color="secondary" url={backUrl} />
          )}

          {!isSuspend && !isHelpDesk && (
            <ConfirmDialogButton
              handler={unInvite}
              disabled={!users.some((u) => u.selected)}
              name="invitation-cancel"
              buttonText="ユーザー解除"
              dialogConfirmText="対象のユーザーをプロジェクトから解除してもよろしいですか？"
              color="secondary"
            />
          )}
        </FooterButtonContainer>
      </Template.Footer>
    </Template.Container>
  )
}

/**
 * 凍結中メッセージバー
 * @returns
 */
const SuspendMessageBar = () => {
  return (
    <div style={{ width: '100%' }}>
      <Alert severity="info" data-test="suspend-message">
        凍結されているプロジェクトのため、変更できません。
      </Alert>
    </div>
  )
}
