import { useHistory } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Button,
  makeStyles,
  Box,
} from '@material-ui/core'
import { Close, ImportContacts } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import { useAuth } from '../../hooks'
import { useLogoutApi } from '../../api'
import { User } from '../../providers'
import { ProductTitleImage } from '../../components/Icon/ProductTitle'

/**
 * ログインユーザーの所属企業名と姓名
 * @returns
 */
const LoginUserInformation: React.FC<{ user: User | null }> = ({ user }) => {
  return (
    <Grid data-test="loginUserInfo">
      <Typography variant="body2" color="textPrimary" align="right">
        {user?.companyName}
        <br />
        {user ? `${user.lastName}${'　'}${user.firstName}さん` : ''}
      </Typography>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  appbar: {
    background: 'transparent',
    boxShadow: 'none',
  },
  toolbar: {
    paddingLeft: '14.5px',
    paddingRight: '14.5px',
  },
  footerText: {
    color: 'white',
    lineHeight: '36px',
  },
  menubar: {
    backgroundColor: (props: HeaderProps) =>
      props.color === 'primary'
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    height: '48px',
  },
}))

type HeaderProps = {
  color?: 'primary' | 'secondary'
}
export const Header: React.FC<Partial<HeaderProps>> = (props?) => {
  const { user, setUser } = useAuth()
  const classes = useStyles(props)

  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { loading, error, doPost } = useLogoutApi()

  const logout = () => {
    doPost()
    if (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }

    if (!loading && !error) {
      setUser(null)
      history.replace({
        pathname: `/logout`,
      })
    }
  }

  return (
    <AppBar position="static" color="primary" className={classes.appbar}>
      <Toolbar className={classes.toolbar}>
        <Grid container justifyContent="space-between" alignItems="center">
          <ProductTitleImage />
          <LoginUserInformation user={user} />
        </Grid>
      </Toolbar>
      <Box className={classes.menubar} boxShadow={2}>
        {user ? (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            style={{ marginTop: '5px' }}
          >
            <div style={{ marginRight: '4px' }}>
              <Button
                variant="outlined"
                color="inherit"
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_MANUAL_URL}/gs/manual/manual-1959/manual-12007`,
                  )
                }
                startIcon={<ImportContacts />}
                size="medium"
              >
                使い方
              </Button>
            </div>
            <div style={{ marginRight: '4px' }}>
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => logout()}
                startIcon={<Close />}
                size="medium"
              >
                閉じる
              </Button>
            </div>
          </Grid>
        ) : null}
      </Box>
    </AppBar>
  )
}
