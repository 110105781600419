/**
 * サービス種別
 */
export const SERVICE_TYPE = {
  /** ファイルポータル */
  SAGYOSHO_NET: 3,
  /** SUPER-TRIO */
  SUPER_TRIO: 4,
  /** 情報セキュリティ */
  TAISEI_SECURITY: 24,
  /** DandALL */
  DANDALL: 25,
  /** DandALLチャット */
  MOBILE_WORKS: 26
} as const;
