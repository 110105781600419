import { makeStyles, Tooltip } from '@material-ui/core'
import * as React from 'react'
import noImage from './img/NO_IMAGE.png'
import sagyoshoNet from './img/SAGYOSHO_NET.png'
import taiseiSecurity from './img/TAISEI_SECURITY.png'
import dandAll from './img/DANDALL.png'
import mobileWorks from './img/MOBILE_WORKS.png'

type ProjectServiceIcon = {
  projectServiceId: string
  projectServiceName: string
}

const useStyles = makeStyles(() => ({
  icon: {
    padding: '4px',
  },
}))

const imageMap: { [key: string]: string } = {
  NO_IMAGE: noImage,
  SAGYOSHO_NET: sagyoshoNet,
  TAISEI_SECURITY: taiseiSecurity,
  DANDALL: dandAll,
  MOBILE_WORKS: mobileWorks,
}

export const ProjectServiceIcon: React.FC<ProjectServiceIcon> = ({
  projectServiceId,
  projectServiceName,
}) => {
  const classes = useStyles()
  const src = imageMap[projectServiceId] || imageMap.NO_IMAGE

  return (
    <Tooltip title={projectServiceName}>
      <img
        src={src}
        alt={`${projectServiceName}`}
        className={classes.icon}
        data-test={`ProjectServiceIcon-${projectServiceId}`}
      />
    </Tooltip>
  )
}
